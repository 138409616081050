<template>
    <div class="flex-col page">
        <Newheader />
        <div class="items-center justify-center head-banner">
            <span class="title">联系我们</span>
        </div>
        <div class="mapBox">
            <el-amap vid="amapDemo" :zoom="zoom" :center="center">
                <el-amap-marker v-for="marker in markers" :position="marker.position" :events="marker.events"
                    :key="marker.index"></el-amap-marker>
                <el-amap-info-window v-for="window in windows" :offset="window.offset" :position="window.position"
                    :content="window.content" :open="window.open" :key="window.index"></el-amap-info-window>
            </el-amap>
        </div>
        <div style=" margin: 0 10rem; background-color: white;" class="flex-row justify-center" >
            <div class="flex-col " style="text-align: start;">
               <span style="font-size: 34px;font-weight: bold;padding: 40px 0 0;">杭州英捷鑫科技有限公司</span>
               <span style="font-size: 24px;padding: 10px 0 40px;">杭州英捷鑫科技有限公司 常州分公司</span>
            </div>
            <div class="flex-col justify-start"  style="text-align: start;margin-left: 100px;">
               <span style="font-size: 24px;font-weight: bold;padding: 50px 0 0;">浙江省杭州市余杭区良渚街道金昌路2800号洋帆中心1号楼12层</span>
               <span style="font-size: 24px;padding: 10px 0 30px;">江苏省常州市新北区吕汤路10号</span>
            </div>
        </div>
        <div class="bigBanner">
            <img src="../assets/images/f2.png"/>
            <div class="bigBanner-text">
                <div class="items-center justify-between lx-item">
                    <div class="lx-item-left">服务热线</div>
                    <div class="lx-item-right">400-808-0395</div>
                </div>
                <div class="jmsq-title">加盟申请</div>
                <div class="items-center justify-between lx-item">
                    <div class="lx-item-left">电动车营销部联系电话</div>
                    <div class="lx-item-right">0519-68197852</div>
                </div>
                <div class="items-center justify-between lx-item mt35">
                    <div class="lx-item-left">联系邮箱</div>
                    <div class="lx-item-right">ev@mail.kymco.com.cn</div>
                </div>
            </div>
        </div>
        <span class="yysj" @click="navPath('/drive')">预约试驾 了解更多</span>
        <newfooter/> 
    </div>
</template>
<script>
export default {
    data() {
        return {
            unit: "",
            address: "",
            markers: [],
            windows: [],
            center: [120.140739, 30.34828],
            zoom: 12,
        }
    },
    mounted() {
        this.initMap();
    },
    methods: {
        // 初始化地图
        initMap(map) {
            this.markers.push({
                position: [120.140739, 30.34828],
            });

            this.windows.push({
                position: [120.140739, 30.34828],
                content:
                    "",
                offset: [0, -20],
                open: true,
            });
            // var map = new AMap.Map("amapDemo", {
            //   zoomEnable: true, //是否禁止缩放
            //   zoom: 12, //缩放比例
            //   dragEnable: false, //禁止拖动
            //   cursor: "hand", // 鼠标在地图上的表现形式，小手
            // });
            // // 初始化工具条
            // map.plugin(["AMap.ToolBar"], function () {
            //   map.addControl(new AMap.ToolBar());
            // });
        },

    }

}
</script>
<style lang="less" scoped>
.title{
    font-size: 54px;
text-align: center;
color: #FFFFFF;
margin-top: -100px;
}
.yysj{
    display: block;
    margin: 0 auto;
    margin-top: 85px !important;
    font-size: 20px;
    font-family: 'Albbr';
    cursor: pointer;
    padding-bottom: 7px;
    border-bottom: 1px solid #000;
    margin-bottom: 215px !important;
}
.mt35 {
    margin-top: 35px;
}

.jmsq-title {
    font-size: 24px;
    margin-top: 70px;
    margin-bottom: 35px;
    font-family: 'Albbr';
}

.lx-item {
    font-size: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid #090909;

    .lx-item-left {
        font-family: 'Albbr';
        font-size: 18px;
    }

    .lx-item-right {
        font-family: 'Albbr';
    }
}

.bigBanner {
    
    margin: 0 100px;
    margin-top: 90px;
    //height: 750px;
    //background-image: url('../assets/images/f2.png');
    //background-size: 100% 100%;
    position: relative;
    .img{
        width: 100%;
        height: 100%;
    }
    .bigBanner-text {
        width: 760px;
        position: absolute;
        right: 96px;
        top: 100px;
        padding: 75px 55px;
        background: rgba(255,255,255,0.9);
    }
}

.page {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100vh;
    background: #F5F5F5;
}

.head-banner {
    width: 100%;
    height: 450px;
    background-image: url(../assets/images/lx/head-banner.png);
    background-size: 100% 100%;
}

.mapBox {
    position: relative;
    z-index: 11;

    height: 600px;
    margin: 0 100px;
    margin-top: -160px;
}

.mapBox-text {

    padding: 35px 0;
    margin: 0 100px;
    text-align: center;
    background: #fff;
    font-size: 32px;
    font-family: 'Albbr';

    .mapBox-text-title {
        font-family: 'Albbr';
    }
}
</style>